import React, { Component } from 'react';
import * as ABB from '@abb/abb-common-ux-react';
import datacenter from '../img/datacenter.png';

export class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: this.getOauth2Code(),
      status: 'Logging in...'
    }
  }

  getOauth2Code() {
    var code = new URLSearchParams(window.location.search).get('code');
    window.history.replaceState(null, null, '/');
    return code;
  }

  getOauth2AutorizeURL() {
    const url = new URL(process.env.REACT_APP_OAUTH2_AUTHORIZE_URL);
    url.searchParams.append('client_id', 'cf4ba733-abd4-4a0a-bc10-2df44bdd3f3c');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('redirect_uri', process.env.REACT_APP_REDIRECT_URI)
    url.searchParams.append('scope', 'openid offline_access email');
    return url.href;
  }

  getOauth2TokenURL(code) {
    const url = new URL(process.env.REACT_APP_GET_OAUTH2_LOGIN_TOKEN_URL);
    url.searchParams.append('code', code);
    url.searchParams.append('redirectUri', encodeURI(process.env.REACT_APP_REDIRECT_URI))
    return url.href;
  }

  login = () => {
    window.location.href = this.getOauth2AutorizeURL();
  }

  signup = () => {
    window.location.href = process.env.REACT_APP_MY_ABB_SIGNUP_URL;
  }

  componentDidMount() {
    if (this.state.code != null) {
      fetch(this.getOauth2TokenURL(this.state.code))
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
          throw response;
        })
        .then(responseJson => {
          if (responseJson.httpStatusCode === 200) {
            var response = JSON.parse(responseJson.response);
            var accessToken = response.access_token;
            var refreshToken = response.refresh_token;
            var idToken = response.id_token;
            if (accessToken != null && refreshToken != null && idToken != null) {
              return this.props.updateTokens(accessToken, refreshToken, idToken);
            }
          }
          throw responseJson;
        }).catch(error => {
          this.setState({ status: 'Login error. Please try again later.' })
          console.error(error)
        });
    }
  }

  render() {
    if (this.state.code != null) {
      return (
        <div className='fullHeight'
          style={{
            backgroundColor: '#FFFFFF',
            height: '100%',
            margin: '8px',
            padding: '10px'
          }}>
          {this.state.status}
        </div>
      );
    }
    return (
      <div className='fullHeight'
        style={{
          backgroundColor: '#FFFFFF',
          height: '100%',
          margin: '8px',
          padding: '10px'
        }}>
        <img src={datacenter} alt="datacenter" style={{ maxHeight: '500px' }} />
        <br />
        <ABB.Button sizeClass={'small'} type={'primary-red'} text={'Login'} onClick={this.login} style={{ margin: '8px' }} />
        <ABB.Button sizeClass={'small'} type={'primary-red'} text={'Sign up'} onClick={this.signup} style={{ margin: '8px' }} />
        <p style={{ fontSize: '0.8em' }}>This system is for the use of authorized users only.
        Individuals using this computer system without
        authority, or in excess of their authority, are subject
        to having all of their activities on this system
        monitored and recorded by system personnel.  In the
        course of monitoring individuals improperly using this
        system, or in the course of system maintenance, the
        activities of authorized users may also be monitored.
        Anyone using this system expressly consents to such
        monitoring and is advised that if such monitoring
        reveals possible evidence of criminal activity, system
        personnel may provide the evidence of such monitoring
        to law enforcement officials.</p>
      </div>
    );
  }
}