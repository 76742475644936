import React, { Component } from 'react';
import * as ABB from '@abb/abb-common-ux-react';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbprintFileName: null,
      thumbprintFileErrors: null,
      serialNumbers: null,
      serialNumbersErrors: null,
      buttonLoading: false,
      loading: true,
      authorized: false
    }
  }

  componentDidMount() {
    // check if the user can use this application
    fetch('api/oauth/check', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.idToken,
      }
    })
      .then(response => {
        if (response.status === 200) {
          return this.setState({ loading: false, authorized: true });
        }
        throw response
      })
      .catch(error => {
        this.setState({ loading: false, authorized: false });
        console.error(error)
      });
  }

  submit = () => {
    // basic validation
    var thumbprintFile = document.getElementById('thumbprintFile');
    if (this.state.thumbprintFileName == null || thumbprintFile.files.length === 0 || this.state.serialNumbers == null) {
      return;
    }
    // reset input errors
    this.setState({ thumbprintFileErrors: null, serialNumbersErrors: null, buttonLoading: true });
    // send data to server
    var data = new FormData();
    data.append('thumbprintFile', thumbprintFile.files[0]);
    var serialNumbersArray = this.state.serialNumbers.split(',');
    for (var i = 0; i < serialNumbersArray.length; i++) {
      data.append('serialNumbers', serialNumbersArray[i]);
    }
    fetch('/api/licenseprovider', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.idToken,
      },
      body: data
    })
      .then((response) => {
        this.setState({ buttonLoading: false });
        // parse response
        if (response.status === 200) {
          // reset form
          this.setState({ thumbprintFileName: null, serialNumbers: null });
          response.blob().then(blob => URL.createObjectURL(blob)).then(url => {
            var a = document.createElement('a');
            a.href = url;
            a.download = 'license';
            // we need to append the element to the dom otherwise it will not work in firefox
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
        }
        else if (response.status === 401) {
          this.setState({ authorized: false })
        }
        else {
          response.json().then(responseJson => {
            if (responseJson.errors != null) {
              var errors = responseJson.errors;
              for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                  if (key === 'ThumbprintFile') {
                    this.setState({ thumbprintFileName: null, thumbprintFileErrors: errors[key] });
                  }
                  else if (key === 'SerialNumbers') {
                    this.setState({ serialNumbers: null, serialNumbersErrors: errors[key] });
                  }
                }
              }
            }
          });
        }
      })
      .catch(error => {
        this.setState({ buttonLoading: false });
        console.error(error)
      });
  }

  render() {
    if (this.state.loading === true) {
      return (
        <div className='fullHeight'
          style={{
            backgroundColor: '#FFFFFF',
            height: '100%',
            margin: '8px',
            padding: '10px'
          }}>
          <ABB.LoadingIndicator
            type='radial'
            determinate={false}
            color='red'
            sizeClass='large'
          />
        </div>
      );
    }
    else if (this.state.authorized === false) {
      return (
        <div className='fullHeight'
          style={{
            backgroundColor: '#FFFFFF',
            height: '100%',
            margin: '8px',
            padding: '10px'
          }}>
          <p>You are not authorized to use this application.<br />
          Please contact <a href='mailto:daniele.acquaroli@it.abb.com'>daniele.acquaroli@it.abb.com</a> to get the access.</p>
        </div>
      );
    }
    return (
      <div className='fullHeight'
        style={{
          backgroundColor: '#FFFFFF',
          height: '100%',
          margin: '8px',
          padding: '10px'
            }}>
        <ABB.Heading level={3} text={'Download'} />
        <a rel="noopener noreferrer" href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK107991A8102&LanguageCode=en&DocumentPartId=&Action=Launch">Docker image for x64 system</a>
            <p>SHA256 5a57e7c8cfc827bfe169cbdd5cf4a937a847be3953c0a21ea6cdac4637eec768</p>
        <a rel="noopener noreferrer" href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK107991A8101&LanguageCode=en&DocumentPartId=&Action=Launch">Docker image for ARM64 system</a>
            <p>SHA256 e8d60dffb6e235b80dd3ce723609cabc374857fbcad1e3903d56a7e4a8ab996f</p>
        <ABB.Heading level={3} text={'Request new iPower license'} style={{ marginTop: '100px'}}/>
        <div style={{ marginTop: '16px', maxWidth: '500px' }}>
          <ABB.Input
            inputAttributes={{ id: 'thumbprintFile' }}
            dataType='file'
            label='Upload the thumbprint file'
            value={this.state.thumbprintFileName}
            onValueChange={v => this.setState({ thumbprintFileName: v })}
            required={true}
            showValidationBarWhenInvalid={false}
            instantValidation={true}
            
          />
        </div>
        <p style={{ color: 'red'}}>{this.state.thumbprintFileErrors}</p>
        <div style={{ marginBottom: '20px', marginTop: '16px', maxWidth: '500px' }}>
          <ABB.Input
            dataType='textarea'
            label='List the serial numbers'
            placeholder='BE87003468,BLB7089319'
            description='Serial numbers must be comma separated'
            value={this.state.serialNumbers}
            onValueChange={v => this.setState({ serialNumbers: v })}
            inputAttributes={{ rows: 7 }}
            resizable={false}
            required={true}
            showValidationBarWhenInvalid={false}
            instantValidation={true}
            
          />
        </div>
        <p style={{ color: 'red'}}>{this.state.serialNumbersErrors}</p>
        <ABB.Button
          sizeClass={'small'}
          type={'primary-red'}
          text={'Get License File'}
          isLoading={this.state.buttonLoading}
          disabled={this.state.thumbprintFileName == null || this.state.serialNumbers == null}
          onClick={this.submit} />
      </div>
    );
  }
}
