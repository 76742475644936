import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Auth } from './components/Auth';
import '@abb/abb-common-ux-react/styles.css';

export default class App extends Component {

  constructor(props) {
    super(props);
    var storedState = JSON.parse(localStorage.getItem('persistent-storage'))
    if (storedState != null) {
      if (storedState.idTokenExpiration != null) {
        if (storedState.idTokenExpiration > (+ new Date()/1000)) {
          // restore stored state only if idToken is still valid
          this.state = storedState;
          return;
        }
      }
    }
    localStorage.removeItem('persistent-storage');
    this.state = {
      idToken: null,
      idTokenExpiration: null,
      userEmail: null
    }
  }

  cleanState() {
    this.setState({ idToken: null, idTokenExpiration: null, userEmail: null });
    localStorage.removeItem('persistent-storage');
  }

  updateTokens = (accessToken, refreshToken, idToken) => {
    try {
      var jwtToken = JSON.parse(atob(idToken.split('.')[1]));
      if (jwtToken.email != null && jwtToken.exp != null) {
        this.setState({ idToken: idToken, idTokenExpiration: jwtToken.exp, userEmail: jwtToken.email });
        localStorage.setItem('persistent-storage', JSON.stringify(this.state))
      }
    } catch (e) {
      this.cleanState();
      console.error(e);
    }
  }

  logout = () => {
    this.cleanState();
    window.location.href = process.env.REACT_APP_OAUTH2_LOGOUT_URL;
  }

  render() {
    var content = <Auth updateTokens={this.updateTokens} />
    if (this.state.idToken != null) {
      content = <Home idToken={this.state.idToken} />
    }
    return (
      <Layout userEmail={this.state.userEmail} logout={this.logout}>
        {content}
      </Layout>
    );
  }
}
