import React, { Component } from 'react';
import * as ABB from '@abb/abb-common-ux-react';

export class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMenuOpen: false
    };
  }

  render() {
    return (
      <ABB.AppContainer theme='light'>
        <ABB.AbbBar productName='iPower – License Provider' />
        <ABB.AppHeader>
          <ABB.AppHeaderSpacer />
          <ABB.AppTopNavi>
            {this.props.userEmail != null && (<ABB.AppTopNaviItem
              text={this.props.userEmail}
              onClick={() => this.toggleUserMenu()}
              onLostFocus={() => this.toggleUserMenu(false)}
              hasMenu={true}>
              {this.state.userMenuOpen && (
                <ABB.Menu onSelect={item => this.toggleUserMenu(false, item)} alignToParent='right'>
                  <ABB.MenuItem text='Logout' itemId='logout' />
                </ABB.Menu>
              )}
            </ABB.AppTopNaviItem>
            )}
          </ABB.AppTopNavi>
        </ABB.AppHeader>
        <ABB.AppContent style={{ width: '100%' }}>
          <ABB.AppMainContent style={{ height: '100%' }}>
            {this.props.children}
          </ABB.AppMainContent>
        </ABB.AppContent>
      </ABB.AppContainer>
    );
  }

  toggleUserMenu(shouldOpen, item) {
    if (item) {
      if (item.id === 'logout') {
        this.props.logout();
      }
    }
    this.setState({ userMenuOpen: shouldOpen !== undefined ? shouldOpen : !this.state.userMenuOpen });
  }
}

